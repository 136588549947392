import React from "react";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import queryString from "query-string";
import window from "global/window";
import { incrementMetric } from "@src/utils/logClient";

const Tutor = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { target, sessionID, classID } = parsedLocation;

  useRedirectHandler(() => {
    if (target === "trialBooking") {
      return "https://tutor.classdojo.com/#/single-class/step-one";
    } else if (target === "previousSession" && sessionID) {
      return `https://tutor.classdojo.com/#/parents/pastClasses/${sessionID}`;
    } else if (target === "previousSession") {
      incrementMetric("external_site.universal_link.fallback", { component: "Tutor" });
      return `https://tutor.classdojo.com/#/parents/pastClasses`;
    } else if (target === "assessment" && classID) {
      return `https://tutor.classdojo.com/#/parents/classes/assessment/${classID}`;
    } else if (target === "subscription" && classID) {
      return `https://tutor.classdojo.com/#/subscription/booking/class/${classID}/redirect-from-class`;
    } else {
      incrementMetric("external_site.universal_link.fallback", { component: "Tutor" });
      return `https://tutor.classdojo.com/#/`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Tutor;
